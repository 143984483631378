/* eslint-disable no-param-reassign, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment, no-underscore-dangle */

// eslint-disable-next-line import/no-extraneous-dependencies
import Event from '@bugsnag/core/types/event';
import PubNub from 'pubnub';

export type CustomErrorStatus = {
  category: string;
};

export class CustomError extends Error {
  status: CustomErrorStatus;

  constructor(message: string, name: string, status: CustomErrorStatus) {
    super(message);
    this.name = name;
    this.status = status;
    this.message = message;
  }
}

export const bugsnagOnError = (
  event: Event,
  error: unknown,
  pubnub?: PubNub
) => {
  if (!pubnub) return;

  const category = (error as CustomError)?.status?.category?.toString?.();

  if (
    category === 'PNNetworkIssuesCategory' ||
    category === 'PNTimeoutCategory'
  ) {
    event.severity = 'info';
  }

  event.addMetadata('pubnub', {
    client: {
      uuid: pubnub.getUUID(),
      // @ts-ignore
      subscribeKey: pubnub._config.subscribeKey,
      // @ts-ignore
      publishKey: pubnub._config.publishKey,
    },
    status: (error as CustomError).status,
  });
};
