import { useEffect, useState } from 'react';
import { restClient } from '@/config/rest-client';

export function useNewCSRFToken() {
  const [CSRFToken, setCSRFToken] = useState<string | null>(null);

  useEffect(() => {
    (async () => {
      const { data } = await restClient().get<{
        csrf_token: string;
      }>('/api/v1/new_csrf_token');
      setCSRFToken(data.csrf_token);
    })();
  }, []);

  return CSRFToken;
}
