import { bugsnag } from '@/utils/bugsnag';
import { isProductionClient } from '@/utils/helpers';

export function logError(msg: string, host?: string) {
  const { href, isProductionHref } = isProductionClient();
  const formattedMsg = `${msg} (url: ${href || host})`;

  if (host === 'tap.az' || host === 'ru.tap.az' || isProductionHref) {
    bugsnag.notify(formattedMsg);
  }

  console.error(formattedMsg);
}
