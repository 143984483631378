import axios from 'axios';
import { CONFIG } from '@/constants/config';
import { makeUrlWithLang } from '@/utils/helpers';
import i18n from '@/i18n';

export const restClient = () => {
  const baseURL = makeUrlWithLang({
    protocol: CONFIG.protocol || '',
    host: CONFIG.host || '',
    path: '/',
    lang: i18n.language,
  });

  return axios.create({ baseURL, withCredentials: true });
};
