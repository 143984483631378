import PubNub from 'pubnub';
import {
  ChannelMessage,
  CustomFieldsMap,
  LastMessagesMap,
} from '@/utils/pubnub/pubnub.types';
import { formatLastMessage } from '@/utils/pubnub/formatLastMessage';

export const transformToLastMessagesMap = (
  pubNubClient: PubNub,
  lastMessages: PubNub.FetchMessagesResponse,
  customFieldsMap: CustomFieldsMap
) => {
  return Object.entries(lastMessages.channels).reduce<LastMessagesMap>(
    (acc, curr) => {
      const channelMessage = curr[1][0] as unknown as ChannelMessage;
      const channel = curr[0];
      const lastReadTimetoken =
        customFieldsMap[channel]?.['last-read-timetoken'];

      return {
        ...acc,
        [channel]: formatLastMessage(
          pubNubClient,
          channelMessage,
          lastReadTimetoken
        ),
      };
    },
    {}
  );
};
