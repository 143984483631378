import { AdWithOrder } from '@/store/useBookmarksStore/useBookmarksStore.types';

export function filterUnique(ads: AdWithOrder[]) {
  const uniqueAdsMap = new Map<string, AdWithOrder>();

  ads.forEach(ad => {
    if (
      !uniqueAdsMap.has(ad.id) ||
      (ad.title && !uniqueAdsMap.get(ad.id)?.title)
    ) {
      uniqueAdsMap.set(ad.id, ad);
    }
  });

  return Array.from(uniqueAdsMap.values());
}
