import { create } from 'zustand';
import {
  BannerSource,
  BannerStore,
} from '@/store/useBannerStore/useBannerStore.types';

export const useBannerStore = create<BannerStore>(set => ({
  isBlockerDetected: false,
  setBlockerDetector: value => set(() => ({ isBlockerDetected: value })),
  bannerTopSource: BannerSource.Adriver,
  setBannerTopSource: value => set(() => ({ bannerTopSource: value })),
  bannerBottomSource: BannerSource.Adriver,
  setBannerBottomSource: value => set(() => ({ bannerBottomSource: value })),
  isAdriverTopBannerVisible: false,
  setIsAdriverTopBannerVisible: value =>
    set(() => ({ isAdriverTopBannerVisible: value })),
}));
