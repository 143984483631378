import PubNub from 'pubnub';
import { transformToLastMessagesMap } from '@/utils/pubnub/transformToLastMessagesMap';
import { CustomFieldsMap } from '@/utils/pubnub/pubnub.types';
import { notifyBugsnagError } from '@/utils/pubnub/notifyBugsnagError';

export const getLastMessages = async (
  pubNubClient: PubNub,
  channels: string[],
  customFieldsMap: CustomFieldsMap
) => {
  try {
    const lastMessage = await pubNubClient.fetchMessages({
      channels,
      count: 1,
    });

    return transformToLastMessagesMap(
      pubNubClient,
      lastMessage,
      customFieldsMap
    );
  } catch (e) {
    notifyBugsnagError(e, pubNubClient);
  }
};
