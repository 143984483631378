import { useMutation, useQuery } from '@apollo/client';
import { GetBaseUserResponse } from '@/api/types/user.types';
import { GET_BASE_USER } from '@/api/queries/user.queries';
import { useLocalStorage } from 'usehooks-ts';
import { useState } from 'react';
import { STORAGE_KEYS } from '@/constants/storageKeys';
import { CREATE_BOOKMARK } from '@/api/mutations/bookmarks.mutations';
import { useBookmarksStore } from '@/store/useBookmarksStore/useBookmarksStore';
import {
  getStoredBookmarks,
  setStoredBookmarks,
} from '@/store/useBookmarksStore/useBookmarksStore.helpers';
import { isJestRunning } from '@/utils/helpers';

export enum AuthStatus {
  Idle,
  Error,
  Authenticated,
  Unauthenticated,
}

// TODO refactor clearing bookmarks after logout will be ready
let isBookmarksAdded = false;

export function useAuth() {
  const [setUserBookmarks] = useBookmarksStore(state => [state.setBookmarks]);

  const [createBookmark] = useMutation(CREATE_BOOKMARK);

  const [authStatus, setAuthStatus] = useLocalStorage(
    STORAGE_KEYS.AUTH_STATUS,
    AuthStatus.Idle
  );

  const [isLoaded, setIsLoaded] = useState(false);

  useQuery<GetBaseUserResponse>(GET_BASE_USER, {
    fetchPolicy: 'network-only',
    onCompleted(data) {
      setIsLoaded(true);

      if (data?.currentUser) {
        const storedBookmarks = getStoredBookmarks().reverse();
        if (!isBookmarksAdded && storedBookmarks.length) {
          createBookmark({
            variables: { adIds: storedBookmarks.map(b => b.id) },
          });

          isBookmarksAdded = true;
        }

        !isJestRunning() && setAuthStatus(AuthStatus.Authenticated);
      }
      if (data?.currentUser === null) {
        if (isBookmarksAdded) {
          setStoredBookmarks([]);
          setUserBookmarks([]);
          isBookmarksAdded = false;
        }
        !isJestRunning() && setAuthStatus(AuthStatus.Unauthenticated);
      }
    },
    onError() {
      setIsLoaded(true);
      !isJestRunning() && setAuthStatus(AuthStatus.Error);
    },
  });

  const isAuthenticated = authStatus === AuthStatus.Authenticated;

  return { authStatus, isLoaded, isAuthenticated };
}
