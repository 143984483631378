import { STORAGE_KEYS } from '@/constants/storageKeys';
import {
  AdWithOrder,
  StoredBookmarks,
} from '@/store/useBookmarksStore/useBookmarksStore.types';
import { filterUnique } from '@/hooks/useUserBookmarks.helpers';
import { sortByObjectProperty } from '@/utils/helpers';

function mapBookmarksArrayToBookmarksObject(
  data: AdWithOrder[]
): StoredBookmarks {
  return data.reduce<StoredBookmarks>(
    (a, c) => ({
      ...a,
      [c.legacyResourceId]: c,
    }),
    {}
  );
}

export function getStoredBookmarks(): AdWithOrder[] {
  if (typeof localStorage === 'undefined') {
    return [];
  }

  const storedObject = JSON.parse(
    localStorage.getItem(STORAGE_KEYS.BOOKMARKS) || '{}'
  ) as StoredBookmarks;

  return sortByObjectProperty<AdWithOrder>(
    Object.values(storedObject).map(item => ({ ...item })),
    'order'
  );
}

export function setStoredBookmarks(updated: AdWithOrder[]): void {
  if (typeof localStorage !== 'undefined') {
    const storedBookmarks = mapBookmarksArrayToBookmarksObject(updated);

    localStorage.setItem(
      STORAGE_KEYS.BOOKMARKS,
      JSON.stringify(storedBookmarks)
    );
  }
}

export function updateStoredBookmarks(newBookmarks: AdWithOrder[]): void {
  const storedBookmarks = getStoredBookmarks();
  const uniques = filterUnique([...storedBookmarks, ...newBookmarks]);

  setStoredBookmarks(uniques);
}
