export const QUERY = {
  q: 'q',
  keywords: 'keywords',
  digitalSnail: 'digital_snail',
  bookmarksIds: 'bIds',
  hasLangChanged: 'hasLangChanged',
};

export enum Languages {
  Ru = 'ru',
  Az = 'az',
}

export const USER_NAME_MAX_LENGTH = 30;

export const BOOKMARKS_BATCH_SIZE = 24;

export enum DateTimeFormat {
  fullTime = 'dd.MM.yyyy, HH:mm',
  fullTimeHyphenated = 'dd-MMM-yy, HH:mm',
  date = 'dd.MM.yyyy',
  timeFirstDate = 'HH:mm, dd.MM.yyyy',
  timeDate = 'HH:mm dd.MM.yyyy',
  time = 'HH:mm',
  longMonthWithYear = 'dd MMMM yyyy',
}

export const OPERATOR_CODE_LENGTH = 3;

export const OPERATOR_CODES = [
  '010',
  '050',
  '051',
  '055',
  '099',
  '070',
  '077',
  '060',
  '044',
  '012',
  '018',
  '020',
  '021',
  '022',
  '023',
  '024',
  '025',
  '026',
  '036',
];
