export enum BannerSource {
  Adriver = 'adriver',
  Admanager = 'admanager',
}

export type SetBannerSource = (value: BannerSource) => void;

export type SetIsAdriverTopBannerVisible = (value: boolean) => void;

export interface BannerStore {
  isBlockerDetected: boolean;
  setBlockerDetector: (value: boolean) => void;
  bannerTopSource: BannerSource;
  setBannerTopSource: SetBannerSource;
  bannerBottomSource: BannerSource;
  setBannerBottomSource: SetBannerSource;
  isAdriverTopBannerVisible: boolean;
  setIsAdriverTopBannerVisible: SetIsAdriverTopBannerVisible;
}
