import {
  ApolloClient,
  createHttpLink,
  from,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { makeUrlWithLang } from '@/utils/helpers';
import { setContext } from '@apollo/client/link/context';
import { CONFIG } from '@/constants/config';
import { logError } from '@/utils/log-error';
import fetch from 'cross-fetch';

let apolloClientSingleton: ApolloClient<NormalizedCacheObject> | null = null;
let currentLang: string | undefined;

export function createApolloClient(lang?: string, CSRFToken?: string) {
  if (!CONFIG.protocol || !CONFIG.host) {
    logError(
      'createApolloClient ERROR: "CONFIG.protocol" or "CONFIG.host" is not defined'
    );
  }

  const uri = makeUrlWithLang({
    protocol: CONFIG.protocol || '',
    host: CONFIG.host || '',
    path: '/graphql',
    lang,
  });

  const httpLink = createHttpLink({
    uri,
    credentials: 'include',
    fetch,
  });

  const authLink = setContext((_, { headers }) => {
    return {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      headers: {
        ...headers,
        ...(CSRFToken && { 'X-Csrf-Token': CSRFToken }),
      },
    };
  });

  if (!apolloClientSingleton || currentLang !== lang) {
    currentLang = lang;

    apolloClientSingleton = new ApolloClient({
      link: from([authLink, httpLink]),
      cache: new InMemoryCache(),
    });
  }

  return apolloClientSingleton;
}
