import { gql } from '@apollo/client';
import { ServiceName } from '@/api/types/ads.types';

export const AD_BASE_FIELDS = gql`
  fragment AdBaseFields on Ad {
    id
    title
    price
    updatedAt
    region
    path
    flags
    legacyResourceId
    shop {
      id
    }
    photo {
      url
    }
  }
`;

export const GET_ADS = (adKind: ServiceName) => {
  const name = `GetAds_${adKind}`;
  return gql`
    ${AD_BASE_FIELDS}
    query ${name} (
      $adKind: AdKindEnum
      $first: Int
      $after: String
      $orderType: AdOrderEnum
    ) {
      ads(
        adKind: $adKind
        first: $first
        after: $after
        source: MOBILE
        orderType: $orderType
      ) {
        nodes {
          ...AdBaseFields
        }
        pageInfo {
          endCursor
        }
      }
    }
  `;
};

export const GET_ADS_FOR_BOOKMARKS = gql`
  ${AD_BASE_FIELDS}
  query GetAdsForBookmarks($ids: [ID!]) {
    ads(ids: $ids, source: DESKTOP) {
      nodes {
        ...AdBaseFields
      }
    }
  }
`;
