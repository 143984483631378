import type { AppProps } from 'next/app';
import { useLocalStorage } from 'usehooks-ts';
import { I18nextProvider } from 'react-i18next';
import { STORAGE_KEYS } from '@/constants/storageKeys';
import { GlobalStyles } from '@/styles/globalStyles';
import { ThemeProvider } from 'styled-components';
import { defaultTheme } from '@/styles/defaultTheme';
import i18n from '@/i18n';
import Script from 'next/script';
import { BlockerDetector } from '@/components/Banners/BlockerDetector';
import NoSsr from '@/components/NoSsr/NoSsr';
import { BannersStyles } from '@/components/Banners/Banners.styles';
import { ApolloProvider } from '@apollo/client';
import { createApolloClient } from '@/config/apollo-client';
import { loadDevMessages } from '@apollo/client/dev';
import { isProduction } from '@/utils/helpers';
import { useEffect } from 'react';
import { initializeGoogleTagManager } from '@/utils/googleTagManager';
import { CONFIG } from '@/constants/config';
import { PubNubInit } from '@/components/PubNubInit/PubNubInit';
import { useNewCSRFToken } from '@/hooks/useNewCSRFToken';
import { useBodyClassIos } from '@/hooks/useBodyClassIos';

if (!isProduction()) {
  loadDevMessages();
}

function App({ Component, pageProps }: AppProps) {
  useBodyClassIos();
  const [theme] = useLocalStorage(STORAGE_KEYS.THEME, defaultTheme);
  const CSRFToken = useNewCSRFToken();

  useEffect(() => {
    initializeGoogleTagManager(CONFIG.gtmContainerId);
  }, []);

  if (!CSRFToken) {
    return null;
  }

  return (
    <>
      <Script src="/scripts/adriver.core.2.js" />

      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <BannersStyles />
        <I18nextProvider i18n={i18n}>
          <ApolloProvider client={createApolloClient(i18n.language, CSRFToken)}>
            <PubNubInit />

            <NoSsr>
              <BlockerDetector />
            </NoSsr>

            <Component {...pageProps} />
          </ApolloProvider>
        </I18nextProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
