import { useMobileDetect } from '@/hooks/useMobileDetect';
import { useEffect } from 'react';

export function useBodyClassIos() {
  const { isIos } = useMobileDetect();

  useEffect(() => {
    if (isIos) {
      document.body.classList.add('ios');
    }
    window.addEventListener('popstate', () => {
      window.location.reload();
    });
  }, []);
}
