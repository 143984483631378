/* eslint-disable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access */

import PubNub from 'pubnub';
import { ChannelMessage } from '@/utils/pubnub/pubnub.types';

export const formatLastMessage = (
  pubNubClient: PubNub,
  channelMessage: ChannelMessage,
  lastReadTimetoken: string | null = null
) => {
  const own =
    (channelMessage?.uuid || channelMessage?.publisher) ===
    pubNubClient.getUUID();

  const isReadMessage = own || channelMessage.timetoken === lastReadTimetoken;

  return {
    isReadMessage,
    lastReadTimetoken,
    timetoken: channelMessage.timetoken,
    marker:
      channelMessage.message?.marker ||
      channelMessage.message?.message?.marker ||
      channelMessage.message?.content?.marker,
    text:
      channelMessage.message?.content?.body?.text ||
      channelMessage.message?.content?.body ||
      null,
  };
};
