export const CONFIG = {
  protocol: process.env.TAPAZ_PROTOCOL || 'https',
  host: process.env.TAPAZ_HOST || 'tap.az',
  bugsnagApiKey: process.env.BUGSNAG_API_KEY || 'DO_NOT_SEND',
  isProduction: process.env.IS_PRODUCTION || 'true',
  languages: ['az', 'ru'],
  gtmContainerId: 'GTM-NDWJDP',
  assetHost: 'tap.azstatic.com',
  fbAppId: '106094292840661',
};
