import { create } from 'zustand';
import { BookmarksStore } from '@/store/useBookmarksStore/useBookmarksStore.types';

export const useBookmarksStore = create<BookmarksStore>(set => ({
  bookmarks: [],
  setBookmarks: value => set({ bookmarks: value }),
  endCursor: '',
  setEndCursor: value => set({ endCursor: value }),
  hasNextPage: true,
  setHasNextPage: value => set({ hasNextPage: value }),
  totalCount: 0,
  setTotalCount: value => set({ totalCount: value }),
  bookmarkIds: new Set(),
  setBookmarkIds: value =>
    set(state => {
      const updated = state.bookmarkIds;
      if (!value.length) {
        updated.clear();
        return { bookmarkIds: updated };
      }

      value.forEach(ad => {
        updated.add(ad.id);
      });

      return { bookmarkIds: updated };
    }),
  loadingState: 'idle',
  setLoadingState: value => set({ loadingState: value }),
}));
