import { useEffect, useState } from 'react';

interface MobileDetectResult {
  isMobile: boolean;
  isDesktop: boolean;
  isAndroid: boolean;
  isIos: boolean;
  isSSR: boolean;
}

const getMobileDetect = (userAgent: string): MobileDetectResult => {
  const isAndroid = () => Boolean(userAgent.match(/Android/i));
  const isIos = () => Boolean(userAgent.match(/iPhone|iPad|iPod/i));
  const isOpera = () => Boolean(userAgent.match(/Opera Mini/i));
  const isWindows = () => Boolean(userAgent.match(/IEMobile/i));
  const isSSR = () => Boolean(userAgent.match(/SSR/i));
  const isMobile = () =>
    Boolean(isAndroid() || isIos() || isOpera() || isWindows());
  const isDesktop = () => Boolean(!isMobile() && !isSSR());

  return {
    isMobile: isMobile(),
    isDesktop: isDesktop(),
    isAndroid: isAndroid(),
    isIos: isIos(),
    isSSR: isSSR(),
  };
};

export const useMobileDetect = (): MobileDetectResult => {
  const [userAgent, setUserAgent] = useState(
    typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent
  );

  useEffect(() => {
    setUserAgent(navigator.userAgent);
  }, []);

  return getMobileDetect(userAgent);
};
