// eslint-disable-next-line import/no-anonymous-default-export
export default {
  name: 'langdomain',

  lookup(options: { lookupFromSubdomainIndex?: unknown }) {
    let found;
    if (typeof window !== 'undefined') {
      const language = window.location.href.match(/(?:http[s]\/\/)*(ru\.)/gi);
      if (language instanceof Array) {
        if (typeof options.lookupFromSubdomainIndex === 'number') {
          found = language[options.lookupFromSubdomainIndex]
            .replace('http://', '')
            .replace('https://', '')
            .replace('.', '');
        } else {
          found = language[0]
            .replace('http://', '')
            .replace('https://', '')
            .replace('.', '');
        }
      }
    }
    return found;
  },
};
