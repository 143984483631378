import PubNub from 'pubnub';
import { formatMemberships } from '@/utils/pubnub/formatMemberships';
import { getLastMessages } from '@/utils/pubnub/getLastMessages';
import { LastMessagesMap } from '@/utils/pubnub/pubnub.types';
import { notifyBugsnagError } from '@/utils/pubnub/notifyBugsnagError';

export const getMemberships = async (
  pubNubClient: PubNub,
  notificationChannelName: string,
  setLastMessagesMap: (value: LastMessagesMap) => void,
  retryCount = 0
) => {
  let res;

  try {
    res = await pubNubClient.objects.getMemberships({
      include: { customFields: true },
    });

    const { allChannelKeys, customFieldsMap } = formatMemberships(res.data);

    pubNubClient.subscribe({
      channels: [notificationChannelName, ...allChannelKeys],
    });

    if (allChannelKeys.length) {
      const lastMessagesMap = await getLastMessages(
        pubNubClient,
        allChannelKeys,
        customFieldsMap
      );

      if (lastMessagesMap) {
        setLastMessagesMap(lastMessagesMap);
      }
    }
  } catch (e) {
    notifyBugsnagError(e, pubNubClient);
  } finally {
    if (res === undefined) {
      if (retryCount < 2) {
        await getMemberships(
          pubNubClient,
          notificationChannelName,
          setLastMessagesMap,
          retryCount + 1
        );
      }
    }
  }
};
