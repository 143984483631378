import { gql } from '@apollo/client';

export const CREATE_BOOKMARK = gql`
  mutation CreateBookmark($adIds: [ID!]!) {
    createBookmark(adIds: $adIds) {
      entity
    }
  }
`;

export const REMOVE_BOOKMARK = gql`
  mutation RemoveBookmark($adId: ID!) {
    destroyBookmark(adId: $adId) {
      entity
    }
  }
`;
