import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import intervalPlural from 'i18next-intervalplural-postprocessor';
import { initReactI18next } from 'react-i18next';
import { CONFIG } from '@/constants/config';
import langdomain from './locales/subdomainLang';
import az from './locales/az.json';
import ru from './locales/ru.json';

const languageDetector = new LanguageDetector();

languageDetector.addDetector(langdomain);

i18n.use(initReactI18next).use(languageDetector).use(intervalPlural);

if (!i18n.isInitialized) {
  i18n
    .init({
      debug: false,
      fallbackLng: 'az',
      load: 'languageOnly',
      resources: {
        az: { translation: az },
        ru: { translation: ru },
      },
      supportedLngs: CONFIG.languages,
      interpolation: {
        escapeValue: false,
      },
      react: {
        useSuspense: false,
        transSupportBasicHtmlNodes: true,
        transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'span'],
      },
      detection: {
        order: ['langdomain'],
      },
    })
    .catch(e => console.error('=== i18n.init ERROR:', e));
}

export default i18n;
