import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { CONFIG } from '@/constants/config';
import { isJestRunning } from '@/utils/helpers';

const NOOP_BUGSNAG_API_KEY = 'DO_NOT_SEND';

class NoopBugsnag {
  notify() {
    console.log('NoopBugsnag - notify', this);
  }

  isStarted() {
    console.log('NoopBugsnag - isStarted', this);
    return false;
  }
}

export function startBugsnag() {
  return isJestRunning() || CONFIG.bugsnagApiKey === NOOP_BUGSNAG_API_KEY
    ? new NoopBugsnag()
    : Bugsnag.start({
        apiKey: CONFIG.bugsnagApiKey,
        plugins: [new BugsnagPluginReact()],
      });
}

export const bugsnag = startBugsnag();
