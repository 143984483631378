/* eslint-disable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call */

import { CONFIG } from '@/constants/config';
import { startBugsnag } from '@/utils/bugsnag';
import PubNub from 'pubnub';
import Bugsnag from '@bugsnag/js';
import { bugsnagOnError, CustomError } from '@/utils/pubnub/bugsnagOnError';
import { isProductionClient } from '@/utils/helpers';

export const notifyBugsnagError = (error: unknown, pubnub?: PubNub) => {
  if (CONFIG.bugsnagApiKey && pubnub) {
    if (!Bugsnag.isStarted()) {
      startBugsnag();
    }

    const { isProductionHref } = isProductionClient();

    // @ts-ignore
    const category = error?.status?.category?.toString?.();

    const finalError = {
      ...(error as object),
      name: category ? `PubNub_${category}` : (error as Error).name,
    };

    const customError =
      finalError instanceof Error
        ? finalError
        : new CustomError(
            // @ts-ignore
            finalError?.status?.message ||
              // @ts-ignore
              finalError?.status?.category?.toString?.(),
            // @ts-ignore
            finalError?.status?.type,
            // @ts-ignore
            finalError?.status
          );

    if (isProductionHref) {
      Bugsnag.notify(customError, event => {
        bugsnagOnError(event, customError);
      });
    }
  }
};
