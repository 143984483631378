import React, { useEffect, useRef } from 'react';
import { useBannerStore } from '@/store/useBannerStore/useBannerStore';

export const BlockerDetector = () => {
  const setBlockerDetector = useBannerStore(state => state.setBlockerDetector);
  const detectorElement = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (detectorElement.current?.clientHeight === 0) {
      setBlockerDetector(true);
    }
  }, []);

  return (
    <div
      id="banner_ad"
      ref={detectorElement}
      className="banner_ad"
      style={{ height: '1px', position: 'fixed', bottom: 0 }}
    />
  );
};
